import axiosInstance from '../../../axiosConfig';

let videoUrl = null;

export const fetchVideo = async () => {
    try {
        const response = await axiosInstance.get('/serve-video/', {
            responseType: 'blob'
        });

        if (response.status === 200) {
            const blob = new Blob([response.data], { type: 'video/mp4' });
            return URL.createObjectURL(blob);
        }
    } catch (error) {
        console.error('Error fetching video:', error);
        if (error.response) {
            console.error('Error response:', error.response.data);
            console.error('Error status:', error.response.status);
        }
    }
    return null;
};

export const getVideoUrl = () => videoUrl;