import CryptoJS from "crypto-js";

const SECRET_KEY = process.env.REACT_APP_LOCAL_DATA_ENC_KEY;


export const encryptData = (data) => {
    if (!SECRET_KEY) {
        throw new Error("Secret key is not defined");
    }
    return CryptoJS.AES.encrypt(data, SECRET_KEY).toString();
};

export const decryptData = (ciphertext) => {
    if (!SECRET_KEY) {
        throw new Error("Secret key is not defined");
    }
    const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
    return bytes.toString(CryptoJS.enc.Utf8);
};
