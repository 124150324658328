import { useState, useEffect, useCallback } from 'react';

export const useNotifications = (isLoggedIn, activationStatus, availableSpins) => {
    const [notifications, setNotifications] = useState([
        { id: 'home', count: 0, notification_message: "You have new updates!" },
        { id: 'download', count: 0 },
        { id: 'shop', count: 0, notification_message: "New items in store!" },
        { id: 'contact', count: 0 },
        { id: 'wheel', count: 0 },
        { id: 'profile', count: 0 }
    ]);

    const updateNotifications = useCallback((id, count, message) => {
        setNotifications(prevNotifications =>
            prevNotifications.map(notification =>
                notification.id === id
                    ? { ...notification, count, notification_message: message }
                    : notification
            )
        );
    }, []);

    useEffect(() => {
        if (isLoggedIn && activationStatus !== null) {
            if (!activationStatus) {
                updateNotifications('profile', 1, "Click here to activate your email");
            } else {
                updateNotifications('profile', 0, "");
            }
        }
    }, [isLoggedIn, activationStatus, updateNotifications]);

    useEffect(() => {
        if (isLoggedIn && availableSpins > 0) {
            updateNotifications('wheel', availableSpins, `You have ${availableSpins} spin${availableSpins > 1 ? 's' : ''} available!`);
        } else {
            updateNotifications('wheel', 0, "");
        }
    }, [isLoggedIn, availableSpins, updateNotifications]);

    return { notifications, updateNotifications };
};