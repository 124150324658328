import React, { useState } from 'react';

const EditableTitle = ({ title, onEdit }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedTitle, setEditedTitle] = useState(title);

    const handleEdit = (e) => {
        e.stopPropagation();
        setIsEditing(true);
    };

    const handleSave = (e) => {
        e.stopPropagation();
        onEdit(editedTitle);
        setIsEditing(false);
    };

    const handleCancel = (e) => {
        e.stopPropagation();
        setEditedTitle(title);
        setIsEditing(false);
    };

    return (
        <div className="editable-title">
            {isEditing ? (
                <>
                    <input
                        type="text"
                        value={editedTitle}
                        onChange={(e) => setEditedTitle(e.target.value)}
                        onClick={(e) => e.stopPropagation()}
                    />
                    <button onClick={handleSave}>Save</button>
                    <button onClick={handleCancel}>Cancel</button>
                </>
            ) : (
                <>
                    <h1 className="collapsible-title">{title}</h1>
                    <button onClick={handleEdit}>Edit</button>
                </>
            )}
        </div>
    );
};

export default EditableTitle;