import React, { useState, useCallback, useEffect } from 'react';
import axiosInstance from '../../../axiosConfig';
import { encryptData } from "../../../app_components/encryptionUtil";
import './ProfileStyle.css';

const SectionLoader = () => (
    <div className="section-loader">
        <div className="spinner"></div>
    </div>
);

const saveToLocalStorage = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
};

const getFromLocalStorage = (key) => {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
};

function ProfileComponent({ onLogout, isLoggedIn, emailActivated, onEmailActivation }) {
    const [activationCode, setActivationCode] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [cooldown, setCooldown] = useState(() => {
        const storedCooldown = getFromLocalStorage('activationCooldown');
        return storedCooldown ? Math.max(0, storedCooldown - Math.floor((Date.now() - getFromLocalStorage('cooldownStartTime')) / 1000)) : 0;
    });
    const [loading, setLoading] = useState(true);
    const [sendingCode, setSendingCode] = useState(false);
    const [submittingCode, setSubmittingCode] = useState(false);
    const [accountInfo, setAccountInfo] = useState(null);
    const [players, setPlayers] = useState([]);
    const [sectionsLoaded, setSectionsLoaded] = useState({
        email: false,
        account: false,
        players: false
    });
    const [sectionLoading, setSectionLoading] = useState({
        email: true,
        account: true,
        players: true
    });
    const [lastRefreshTime, setLastRefreshTime] = useState(() => getFromLocalStorage('lastRefreshTime') || 0);
    const [refreshCooldown, setRefreshCooldown] = useState(() => {
        const storedRefreshTime = getFromLocalStorage('lastRefreshTime');
        return storedRefreshTime ? Math.max(0, 60 - Math.floor((Date.now() - storedRefreshTime) / 1000)) : 0;
    });
    const cooldownTime = 300; // 5 minutes in seconds

    const triggerSectionLoads = () => {
        setTimeout(() => {
            setSectionsLoaded(prev => ({ ...prev, email: true }));
            setSectionLoading(prev => ({ ...prev, email: false }));
        }, 100);
        setTimeout(() => {
            setSectionsLoaded(prev => ({ ...prev, account: true }));
            setSectionLoading(prev => ({ ...prev, account: false }));
        }, 300);
        setTimeout(() => {
            setSectionsLoaded(prev => ({ ...prev, players: true }));
            setSectionLoading(prev => ({ ...prev, players: false }));
        }, 500);
    };

    const fetchAllData = useCallback(async () => {
        setLoading(true);
        const cachedEmail = getFromLocalStorage('userEmail');
        const cachedAccountInfo = getFromLocalStorage('accountInfo');
        const cachedPlayers = getFromLocalStorage('players');

        if (cachedEmail && cachedAccountInfo && cachedPlayers) {
            setUserEmail(cachedEmail);
            setAccountInfo(cachedAccountInfo);
            setPlayers(cachedPlayers);
            setLoading(false);
            triggerSectionLoads();
            return;
        }

        try {
            const [emailResponse, accountResponse, playersResponse] = await Promise.all([
                axiosInstance.get('/get-user-email/'),
                axiosInstance.get('/account/'),
                axiosInstance.get('/account/players/')
            ]);

            setUserEmail(emailResponse.data.email);
            setAccountInfo(accountResponse.data);
            setPlayers(playersResponse.data);

            saveToLocalStorage('userEmail', emailResponse.data.email);
            saveToLocalStorage('accountInfo', accountResponse.data);
            saveToLocalStorage('players', playersResponse.data);
            setError('');
        } catch (err) {
            console.error('Error fetching data:', err);
            setError('Failed to fetch user data. Please try again.');
        } finally {
            setLoading(false);
            triggerSectionLoads();
        }
    }, []);

    const handleRefresh = useCallback(() => {
        if (refreshCooldown === 0) {
            setRefreshCooldown(60);
            setLastRefreshTime(Date.now());
            saveToLocalStorage('lastRefreshTime', Date.now());
            localStorage.removeItem('userEmail');
            localStorage.removeItem('accountInfo');
            localStorage.removeItem('players');
            fetchAllData();
        }
    }, [refreshCooldown, fetchAllData]);

    useEffect(() => {
        const shouldFetchData = !getFromLocalStorage('userEmail') ||
            !getFromLocalStorage('accountInfo') ||
            !getFromLocalStorage('players');

        if (shouldFetchData) {
            fetchAllData();
        } else {
            setUserEmail(getFromLocalStorage('userEmail'));
            setAccountInfo(getFromLocalStorage('accountInfo'));
            setPlayers(getFromLocalStorage('players'));
            setLoading(false);
            triggerSectionLoads();
        }
    }, [fetchAllData]);

    useEffect(() => {
        const handleTabClose = () => {
            localStorage.removeItem('userEmail');
            localStorage.removeItem('accountInfo');
            localStorage.removeItem('players');
        };

        window.addEventListener('beforeunload', handleTabClose);

        return () => {
            window.removeEventListener('beforeunload', handleTabClose);
        };
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            setCooldown(prevCooldown => {
                const newCooldown = Math.max(0, prevCooldown - 1);
                saveToLocalStorage('activationCooldown', newCooldown);
                return newCooldown;
            });

            setRefreshCooldown(prevCooldown => {
                const newCooldown = Math.max(0, prevCooldown - 1);
                return newCooldown;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        if (message) {
            const timer = setTimeout(() => setMessage(''), 2300);
            return () => clearTimeout(timer);
        }
    }, [message]);


    const handleSendCode = async () => {
        if (!userEmail) return;
        setSendingCode(true);
        try {
            const response = await axiosInstance.post('/send-activation-email/', { email: userEmail, cooldown: cooldownTime });
            setMessage(response.data.message);
            setError('');
            setCooldown(cooldownTime);
            saveToLocalStorage('activationCooldown', cooldownTime);
            saveToLocalStorage('cooldownStartTime', Date.now());
        } catch (err) {
            if (err.response?.status === 429) {
                // Handle cooldown response
                const remainingSeconds = err.response.data.remaining_seconds;
                setCooldown(remainingSeconds);
                saveToLocalStorage('activationCooldown', remainingSeconds);
                saveToLocalStorage('cooldownStartTime', Date.now());
                setError(`Please wait ${formatTime(remainingSeconds)} before requesting a new code.`);
            } else {
                setError(err.response?.data?.error || 'An error occurred while sending the activation code');
            }
            setMessage('');
        } finally {
            setSendingCode(false);
        }
    };

    const handleSubmitCode = async () => {
        if (!userEmail) return;
        setSubmittingCode(true);
        try {
            const response = await axiosInstance.post('/validate-activation-code/', {
                email: userEmail,
                activation_code: activationCode
            });

            if (response.data.access) {
                const encryptedAccessToken = encryptData(response.data.access);
                localStorage.setItem('access_token', encryptedAccessToken);

                if (response.data.refresh) {
                    const encryptedRefreshToken = encryptData(response.data.refresh);
                    localStorage.setItem('refresh_token', encryptedRefreshToken);
                }

                axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;
            }

            setMessage(response.data.message);
            setError('');
            onEmailActivation();
        } catch (err) {
            setError(err.response?.data?.error || 'An error occurred while validating the activation code');
            setMessage('');
        } finally {
            setSubmittingCode(false);
        }
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    if (loading) {
        return (
            <div className="profile-component">
                <SectionLoader />
            </div>
        );
    }

    return (
        <div className="profile-component">
            <div className="profile-control-btns">
                <button
                    className={`refresh-button ${refreshCooldown > 0 ? 'disabled' : ''}`}
                    onClick={handleRefresh}
                    disabled={refreshCooldown > 0}
                >
                    {refreshCooldown > 0 ? `Refresh in ${formatTime(refreshCooldown)}` : 'Refresh'}
                </button>
                <button className="profile-logout-button" onClick={onLogout}>
                    Logout
                </button></div>

            <div className='profile-title-container'>
                <h2>User Profile</h2>
            </div>
            <div className={`user-information-container fade-in ${sectionsLoaded.email ? 'loaded' : ''}`}>
                {sectionLoading.email ? (
                    <SectionLoader />
                ) : (
                    <>
                        {userEmail && <p>Email: {userEmail}</p>}
                        {!emailActivated && (
                            <div className="email-activation">
                                <p>Your email is not activated. Please activate your email to access all features.</p>
                                <button
                                    className={`send-act-codebtn ${sendingCode ? 'loading' : ''}`}
                                    onClick={handleSendCode}
                                    disabled={cooldown > 0 || sendingCode}
                                >
                                    <span className="button-text">
                                        {cooldown > 0 ? `Resend in ${formatTime(cooldown)}` : 'Send Activation Code'}
                                    </span>
                                    <span className="loading-text">Sending code</span>
                                </button>
                                <div className='submit-code-container'>
                                    <input
                                        type="text"
                                        value={activationCode}
                                        onChange={(e) => setActivationCode(e.target.value)}
                                        placeholder="Enter activation code"
                                    />
                                    <button
                                        className={`submit-code-btn ${submittingCode ? 'loading' : ''}`}
                                        onClick={handleSubmitCode}
                                        disabled={submittingCode}
                                    >
                                        {submittingCode ? 'Submitting...' : 'Submit Code'}
                                    </button>
                                </div>
                            </div>
                        )}
                        {emailActivated && (
                            <p className="activation-status">Your account is activated.</p>
                        )}
                        {message && <p className="success-message">{message}</p>}
                        {error && <p className="error-message">{error}</p>}
                    </>
                )}
            </div>
            <div className={`account-info-container fade-in ${sectionsLoaded.account ? 'loaded' : ''}`}>
                {sectionLoading.account ? (
                    <SectionLoader />
                ) : accountInfo && (
                    <>
                        <h3>Account Information</h3>
                        <p>Login: {accountInfo.login}</p>
                        <p>Created On: {new Date(accountInfo.create_time).toLocaleDateString()}</p>
                        <p>Last Play: {new Date(accountInfo.last_play).toLocaleDateString()}</p>
                        <p>Email Validated: {accountInfo.validated_email ? 'Yes' : 'No'}</p>
                    </>
                )}
            </div>
            <div className={`players-container fade-in ${sectionsLoaded.players ? 'loaded' : ''}`}>
                {sectionLoading.players ? (
                    <SectionLoader />
                ) : players.length > 0 && (
                    <>
                        <h3 className='char-title'>Characters</h3>
                        <div className="charcaters-container">
                            {players.map(player => (
                                <div key={player.id} className="player-card">
                                    <img className='char-img' src={player.char_image} alt={`Character ${player.name}`} />
                                    <p>Name: {player.name}</p>
                                    <p>Job: {player.job}</p>
                                    <p>Level: {player.level}</p>
                                    <p>Experience: {player.exp}</p>
                                    <p>Gold: {player.gold}</p>
                                    <p>Last Play: {new Date(player.last_play).toLocaleDateString()}</p>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>

        </div>
    );
}

export default ProfileComponent;