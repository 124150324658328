import React, { memo } from 'react';
import { Wheel } from 'react-custom-roulette';

const MemoizedWheel = memo(({ mustSpin, prizeNumber, data, onStopSpinning }) => (
    <Wheel
        mustStartSpinning={mustSpin}
        prizeNumber={prizeNumber}
        data={data}
        onStopSpinning={onStopSpinning}
        outerBorderColor="transparent"
        outerBorderWidth={10}
        innerRadius={40}
        innerBorderColor="#30261a"
        innerBorderWidth={0}
        radiusLineColor="#30261a"
        radiusLineWidth={2}
        spinDuration={0.8}
        fontFamily="Arial"
        fontSize={16}
        perpendicularText={false}
        textDistance={95}
        pointerProps={{
            style: {
                position: 'absolute',
                transform: 'translateX(-75%) translateY(89%) rotate(225deg)',
                width: '0',
                height: '0',
                borderLeft: '20px solid transparent',
                borderRight: '20px solid transparent',
                borderBottom: '40px solid #f0ad4e',
            },
        }}
    />
));

export default MemoizedWheel;