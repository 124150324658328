import React from "react";
import "./downloadClientStyle.css";

// DownloadButton Component
const DownloadButton = ({ btnId, fileName, disabled, sourceInfo, megaDownloadUrl, newWindow }) => {
    const handleDownload = () => {
        if (disabled) return;

        // Create an anchor element and trigger download
        const link = document.createElement('a');
        link.href = megaDownloadUrl; // Using the provided URL
        if (newWindow) {
            link.target = '_blank'; // Open in new tab if newWindow is true
        } else {
            link.setAttribute('download', fileName); // Trigger download in the same tab
        }
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Add the "downloaded" class to the button
        const btnDownload = document.getElementById(btnId);
        btnDownload.classList.add('downloaded');

        // Remove the "downloaded" class after 2 seconds to reset animation
        setTimeout(() => {
            btnDownload.classList.remove('downloaded');
        }, 2000);
    };

    return (
        <div className="download-content-container">
            <div className="source-info">{sourceInfo}</div>
            <div id={btnId} onClick={handleDownload} className={`download-button ${disabled ? 'disabled' : ''}`}>
                <svg width="22px" height="22px" viewBox="0 0 16 16">
                    <path d="M2,10 L6,13 L12.8760559,4.5959317 C14.1180021,3.0779974 16.2457925,2.62289624 18,3.5 L18,3.5 C19.8385982,4.4192991 21,6.29848669 21,8.35410197 L21,10 C21,12.7614237 18.7614237,15 16,15 L1,15" id="check"></path>
                    <polyline points="4.5 8.5 8 11 11.5 8.5" className="svg-out"></polyline>
                    <path d="M8,1 L8,11" className="svg-out"></path>
                </svg>
            </div>
        </div>
    );
};

// Main Component
const DownloadClient = () => {
    return (
        <div className="download-client-main">
            <h1 className="download-header">Download</h1>
            <div className="download-options">
                <DownloadButton
                    btnId="btn-download1"
                    fileName="metin2_client.rar"
                    disabled={false}
                    sourceInfo="Game Client"
                    megaDownloadUrl={process.env.REACT_APP_CLIENT_LINK}
                    newWindow={true}
                />
                <DownloadButton
                    btnId="btn-download3"
                    fileName="metin2-Patcher.exe"
                    disabled={false}
                    sourceInfo="Auto Patcher"
                    megaDownloadUrl={process.env.REACT_APP_PATCHER_LINK}
                    newWindow={false}
                />
                <DownloadButton
                    btnId="btn-download2"
                    fileName="metin2_client.rar"
                    disabled={true}
                    sourceInfo="Alternative 1"
                    megaDownloadUrl={process.env.REACT_APP_ALTERNATIVE_CLIENT_LINK}
                    newWindow={true}
                />
            </div>
        </div>
    );
};

export default DownloadClient;
