import React, { useState, useEffect, useRef } from "react";
import "./SignInSignUpForm.css";
import { FaUser, FaLock, FaLockOpen, FaTrashAlt, FaUserShield, FaExclamationCircle, FaEnvelope, FaKey } from "react-icons/fa";
import { loginUser, createUser, resetPassword, sendPasswordResetCode } from "./authService";
import { encryptData, decryptData } from "../encryptionUtil";
import { AnimatePresence, motion } from "framer-motion";
import { useUser } from "../../UserContext";

const SignInSignUpForm = ({ toggleForm, onLoginSuccess, setSelectedOption, setSuddenClose }) => {
  const [formType, setFormType] = useState("signin");
  const [rememberMe, setRememberMe] = useState(false);
  const formRef = useRef();
  const { setUser } = useUser();

  const [username, setUsername] = useState(localStorage.getItem("signupUsername") || "");
  const [password, setPassword] = useState(localStorage.getItem("signupPassword") ? decryptData(localStorage.getItem("signupPassword")) : "");
  const [email, setEmail] = useState(localStorage.getItem("signupEmail") || "");
  const [socialId, setSocialId] = useState(localStorage.getItem("signupSocialId") ? decryptData(localStorage.getItem("signupSocialId")) : "");
  const [securityCode, setSecurityCode] = useState(localStorage.getItem("signupSecurityCode") ? decryptData(localStorage.getItem("signupSecurityCode")) : "");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [highlightRed, setHighlightRed] = useState("");
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [isEmailActivated, setIsEmailActivated] = useState(true);

  const [resetCode, setResetCode] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validateSocialId = (id) => {
    const re = /^\d{7}$/;
    return re.test(id);
  };

  const handleFormTypeToggle = (e) => {
    e.preventDefault();
    setFormType((prevType) => (prevType === "signin" ? "signup" : "signin"));
  };

  const handleSendResetCode = async () => {
    setErrorMessage("");
    setSuccessMessage("");
    try {
      const response = await sendPasswordResetCode(email);
      setSuccessMessage("Reset code sent to your email.");
    } catch (error) {
      if (error.response && error.response.status === 429) {
        setErrorMessage("Code already sent. " + error.response.data.error);
      } else {
        setErrorMessage("Failed to send reset code. Please try again.");
      }
    }
  };

  const handleResetPassword = async () => {
    setErrorMessage("");
    setSuccessMessage("");
    try {
      const response = await resetPassword(email, resetCode, newPassword);
      setSuccessMessage("Password reset successfully.");
      setTimeout(() => {
        setFormType("signin");
      }, 2000);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setErrorMessage(error.response.data.error);
      } else {
        setErrorMessage("Failed to reset password. Please try again later, report this incident to an admin");
      }
    }
  };


  useEffect(() => {
    const checkClickOutside = (e) => {
      if (formRef.current && !formRef.current.contains(e.target)) {
        const parentElement = e.target.closest("li");
        if (!parentElement || parentElement.id !== "profile") {
          toggleForm();
        }
      }
    };

    document.addEventListener("mousedown", checkClickOutside);
    return () => {
      document.removeEventListener("mousedown", checkClickOutside);
    };
  }, [toggleForm]);

  useEffect(() => {
    setErrorMessage("");
  }, [formType]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
    setHighlightRed("");

    if (formType === "signin") {
      if (!validateEmail(loginEmail)) {
        setErrorMessage("Invalid email format");
        setHighlightRed("email");
        return;
      }

      if (!loginPassword || loginPassword.length === 0) {
        setErrorMessage("No password provided");
        setHighlightRed("password");
        return;
      }

      try {
        const response = await loginUser(loginEmail, loginPassword);

        if (response.status === 200) {
          setUser({
            username: response.data.login,
            isAdmin: response.data.is_admin,
            emailActivated: response.data.email_activated,
          });
          setSuccessMessage("You have been successfully logged in.");
          setIsEmailActivated(response.data.email_activated);
          const closeDelay = response.data.email_activated ? 1000 : 12320000;
          localStorage.setItem("access_token", encryptData(response.data.access_token));
          onLoginSuccess(response.data.email_activated);
          if (!response.data.email_activated)
            setSuddenClose(true);
          setTimeout(() => {
            toggleForm();
          }, closeDelay);
        }
      } catch (error) {
        if (error.response) {
          setErrorMessage(error.response.data.error);
        }
      }
    } else if (formType === "signup") {
      if (!username || username.length < 3 || username.length > 15) {
        setErrorMessage("Username must be 3-15 characters long");
        setHighlightRed("username");
        return;
      }
      if (!validateEmail(email)) {
        setErrorMessage("Invalid email format");
        setHighlightRed("email");
        return;
      }
      if (!password || password.length < 8) {
        setErrorMessage("Password must be at least 8 characters long");
        setHighlightRed("password");
        return;
      }
      if (!socialId) {
        setErrorMessage("Delete Code is required");
        setHighlightRed("socialId");
        return;
      }
      if (!validateSocialId(socialId)) {
        setErrorMessage("Delete Code must be exactly 7 digits long");
        setHighlightRed("socialId");
        return;
      }
      if (!securityCode) {
        setErrorMessage("Security code is required");
        setHighlightRed("securityCode");
        return;
      }

      try {
        const response = await createUser(username, password, email, socialId, securityCode);

        if (response.status === 201) {
          setSuccessMessage("Account created successfully!");
          // setIsEmailActivated(false);
          setTimeout(() => {
            // Clear the localStorage for signup inputs after successful account creation
            localStorage.removeItem("signupUsername");
            localStorage.removeItem("signupPassword");
            localStorage.removeItem("signupEmail");
            localStorage.removeItem("signupSocialId");
            localStorage.removeItem("signupSecurityCode");
            setFormType("signin");
          }, 3000); // Increased to 3 seconds for new accounts
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          setErrorMessage(error.response.data.email);
          if (error.response.data.email) {
            try {
              const emailErrors = error.response.data.email;
              if (Array.isArray(emailErrors) && emailErrors.length > 0) {
                setErrorMessage(emailErrors[0]);
                setHighlightRed("email");
              }
            } catch (e) {
              setErrorMessage("An error occurred while processing the email error.");
            }
          }
          else if (error.response.data.error === "Email provider not accepted. Please use one of the following: gmail, yahoo, hotmail, outlook, aol, icloud, protonmail, mail, zoho") {
            {
              setErrorMessage(error.response.data.error);
              setHighlightRed("email");
            }
          }
          else
            setErrorMessage("Bad request, check input data or contact administrator!");
        }
        else if (error.response.status === 409) {
          setHighlightRed("username");
          setErrorMessage("Username already used!");
        } else {
          setErrorMessage("An unexpected error occurred, contact administrator!");
        }
      }
    } else if (formType === "reset") {
      console.log("Reset password logic here");
      // Implement password reset logic
    }
  };

  const handleCheckboxClick = () => {
    setRememberMe(!rememberMe);
  };

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  const getHeader = () => {
    switch (formType) {
      case "signin":
        return "Sign In";
      case "signup":
        return "Sign Up";
      case "reset":
        return "Password Recovery";
      default:
        return "Welcome";
    }
  };

  const handleSignupInputChange = (setter, value, key) => {
    setter(value);
    if (key === "signupPassword" || key === "signupSocialId" || key === "signupSecurityCode") {
      localStorage.setItem(key, encryptData(value));
    } else {
      localStorage.setItem(key, value);
    }
  };

  return (
    <div className="overlay-signin">
      <div className={`signin-signup-modal ${formType}`} ref={formRef}>
        <h1>{getHeader()}</h1>
        <button className="close-button" onClick={toggleForm}>
          &times;
        </button>

        <form className="form-content" onSubmit={handleSubmit}>
          {formType === "signin" && (
            <div className="singup-box">
              <div className={`input-box ${highlightRed === "email" ? "error_field_highlight" : ""}`}>
                <input
                  name="email"
                  type="text"
                  placeholder="E-Mail"
                  value={loginEmail}
                  onChange={(e) => setLoginEmail(e.target.value)}
                />
                <FaUser className="icon-login" />
              </div>
              <div className={`input-box ${highlightRed === "password" ? "error_field_highlight" : ""}`}>
                <input
                  name="password"
                  type="password"
                  placeholder="Password"
                  value={loginPassword}
                  onChange={(e) => setLoginPassword(e.target.value)}
                />
                <FaLock className="icon-login" />
              </div>
              <div className="checkbox-container">
                <input
                  name="checkbox"
                  type="checkbox"
                  checked={rememberMe}
                  onChange={handleRememberMeChange}
                  className="futuristic-checkbox"
                />
                <label htmlFor="rememberMe" className="checkbox-label" onClick={handleCheckboxClick}>
                  Remember Me
                </label>
              </div>
              {errorMessage && (
                <div className="signin-error-container fade-in">
                  <FaExclamationCircle className="error-icon" />
                  <span className="signin-error-message">{errorMessage}</span>
                </div>
              )}
            </div>
          )}
          {formType === "signup" && (
            <>
              <div className={`input-box ${highlightRed === "username" ? "error_field_highlight" : ""}`}>
                <input
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => handleSignupInputChange(setUsername, e.target.value, "signupUsername")}
                />
                <FaUser className="icon-login" />
              </div>
              <div className={`input-box ${highlightRed === "password" ? "error_field_highlight" : ""}`}>
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => handleSignupInputChange(setPassword, e.target.value, "signupPassword")}
                />
                <FaLock className="icon-login" />
              </div>
              <div className={`input-box ${highlightRed === "email" ? "error_field_highlight" : ""}`}>
                <input
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => handleSignupInputChange(setEmail, e.target.value, "signupEmail")}
                />
                <FaEnvelope className="icon-login" />
              </div>
              <div className={`input-box ${highlightRed === "socialId" ? "error_field_highlight" : ""}`}>
                <input
                  type="text"
                  placeholder="Character Delete Code"
                  value={socialId}
                  onChange={(e) => handleSignupInputChange(setSocialId, e.target.value, "signupSocialId")}
                />
                <FaTrashAlt className="icon-login" />
              </div>
              <div className={`input-box ${highlightRed === "securityCode" ? "error_field_highlight" : ""}`}>
                <input
                  type="text"
                  placeholder="Security Code"
                  value={securityCode}
                  onChange={(e) => handleSignupInputChange(setSecurityCode, e.target.value, "signupSecurityCode")}
                />
                <FaUserShield className="icon-login" />
              </div>
              {errorMessage && (
                <div className="error-container fade-in">
                  <FaExclamationCircle className="error-icon" />
                  <span className="signinup-error-message">{errorMessage}</span>
                </div>
              )}
            </>
          )}


          {formType === "reset" && (
            <>
              <div className="input-box reset-pass-box">
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <FaEnvelope className="icon-login" />
              </div>
              <div className="input-box reset-pass-box">
                <input
                  type="text"
                  placeholder="Reset Code"
                  value={resetCode}
                  onChange={(e) => setResetCode(e.target.value)}
                />
                <FaKey className="icon-login" />
              </div>
              <div className="input-box reset-pass-box">
                <input
                  type="password"
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <FaLockOpen className="icon-login" />
              </div>
              <button
                className="reset-code-btn"
                type="button"
                onClick={handleSendResetCode}
              >
                Send Reset Code
              </button>
              <button
                className="submitbtn"
                type="button"
                onClick={handleResetPassword}
              >
                Reset Password
              </button>
              <a href="#" className="back-to-signin" onClick={() => setFormType("signin")}>
                Back to login.
              </a>
              {errorMessage && (
                <div className="error-container fade-in">
                  <FaExclamationCircle className="error-icon" />
                  <span className="signinup-error-message">{errorMessage}</span>
                </div>
              )}
            </>
          )}


          {(formType === "signin" || formType === "signup") && (
            <button className="submitbtn" type="submit">
              {formType === "signin" ? "Sign In" : "Sign Up"}
            </button>
          )}

        </form>
        {successMessage && (
          <div className="success-message">
            <div className="success-icon">&#10003;</div>
            {successMessage}
          </div>
        )}
        <div className="account-actions">
          {formType !== "reset" && (
            <div className="signup-box">
              {formType === "signin" ? (
                <p>Don't have an account?</p>
              ) : (
                <p>Already have an account?</p>
              )}
              <a href="#" onClick={handleFormTypeToggle}>
                {formType === "signin" ? "Create Account" : "Login"}
              </a>
            </div>
          )}

          {formType === "signin" && (
            <a href="#" className="forgot-pass" onClick={() => setFormType("reset")}>
              Forgot Password?
            </a>
          )}


        </div>
        <AnimatePresence>
          {!isEmailActivated && (
            <motion.div
              className="email-not-activated-container"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              Email not activated. Head to{" "}
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  toggleForm();
                  setSelectedOption("profile");  // Add this to render the profile component
                }}
                style={{ color: '#007bff', textDecoration: 'underline', cursor: 'pointer' }}
              >
                your profile
              </a>{" "}
              to activate it.
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default SignInSignUpForm;