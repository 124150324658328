// WheelAdmin.js
import React, { useState, useMemo, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash, faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import { motion, AnimatePresence } from 'framer-motion';
import axiosInstance from '../../../axiosConfig';
import './WheelAdmin.css';

export default function WheelAdmin({ prizes, setPrizes }) {
    const [editingPrize, setEditingPrize] = useState(null);
    const [editingPrizeId, setEditingPrizeId] = useState(null);
    const [newPrize, setNewPrize] = useState({
        text: '',
        image_uri: '',
        style: 'linear-gradient(to right, #ff416c, #ff4b2b)',
        weight: 1,
        vnum: 0,
        count: 1
    });
    const [isAdminControlsVisible, setIsAdminControlsVisible] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const handleAddPrize = async () => {
        try {
            const response = await axiosInstance.post('/wheel-awards/admin/prizes/create/', newPrize);
            setPrizes([...prizes, response.data]);
            setNewPrize({
                text: '',
                image_uri: '',
                style: 'linear-gradient(to right, #ff416c, #ff4b2b)',
                weight: 1,
                vnum: 0,
                count: 1
            });
        } catch (error) {
            console.error('Error adding prize:', error);
        }
    };

    const handleUpdatePrize = async (id) => {
        try {
            const prizeToUpdate = {
                id: editingPrize.id,
                text: editingPrize.text,
                image_uri: editingPrize.image_uri,
                style: editingPrize.style,
                weight: editingPrize.weight,
                vnum: editingPrize.vnum,
                count: editingPrize.count
            };
            console.log("Updating prize:", prizeToUpdate);
            const response = await axiosInstance.put(`/wheel-awards/admin/prizes/${id}/update/`, prizeToUpdate);
            console.log("Server response:", response.data);
            setPrizes(prizes.map(p => p.id === id ? response.data : p));
            setEditingPrize(null);
            setEditingPrizeId(null);
        } catch (error) {
            console.error('Error updating prize:', error);
        }
    };

    const handleDeletePrize = async (id) => {
        try {
            await axiosInstance.delete(`/wheel-awards/admin/prizes/${id}/delete/`);
            setPrizes(prizes.filter(prize => prize.id !== id));
        } catch (error) {
            console.error('Error deleting prize:', error);
        }
    };

    const toggleAdminControls = () => {
        setIsAdminControlsVisible(!isAdminControlsVisible);
    };

    const handleEditClick = (prize) => {
        setEditingPrizeId(prize.id);
        setEditingPrize({
            id: prize.id,
            text: prize.text,
            image_uri: prize.image_uri.uri,
            style: prize.style.backgroundColor,
            weight: prize.weight,
            vnum: prize.vnum,
            count: prize.count
        });
    };

    const handleCancelEdit = () => {
        setEditingPrizeId(null);
        setEditingPrize(null);
    };

    const filteredPrizes = useMemo(() => {
        return prizes.filter(prize =>
            prize.text.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [prizes, searchTerm]);

    return (
        <div className="admin-controls-container">
            <h2 className="admin-title" onClick={toggleAdminControls}>
                Admin Controls
                <span className="arrow-container">
                    <FontAwesomeIcon
                        icon={faChevronDown}
                        className={`arrow ${isAdminControlsVisible ? 'up' : ''}`}
                    />
                </span>
            </h2>
            <AnimatePresence>
                {isAdminControlsVisible && (
                    <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        className="admin-controls"
                    >
                        <div className="search-bar">
                            <FontAwesomeIcon icon={faSearch} />
                            <input
                                type="text"
                                placeholder="Search prizes..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <div className="prize-list">
                            {filteredPrizes.map((prize) => (
                                <div key={prize.id} className="prize-item">
                                    <span>Text: {prize.text}</span>
                                    <img src={prize.image_uri.uri} alt={prize.text} className="prize-thumbnail" />
                                    <span>Style: {prize.style.backgroundColor}</span>
                                    <span>Weight: {prize.weight}</span>
                                    <span>Vnum: {prize.vnum}</span>
                                    <span>Count: {prize.count}</span>

                                    {editingPrizeId === prize.id ? (
                                        <div className="edit-form">
                                            <label htmlFor="edit-text">Prize Text</label>
                                            <input
                                                id="edit-text"
                                                placeholder="Prize text"
                                                value={editingPrize.text}
                                                onChange={(e) => setEditingPrize({ ...editingPrize, text: e.target.value })}
                                            />
                                            <label htmlFor="edit-image">Image URL</label>
                                            <input
                                                id="edit-image"
                                                placeholder="Image URL"
                                                value={editingPrize.image_uri}
                                                onChange={(e) => setEditingPrize({ ...editingPrize, image_uri: e.target.value })}
                                            />
                                            <label htmlFor="edit-style">Style</label>
                                            <input
                                                id="edit-style"
                                                placeholder="Style"
                                                value={editingPrize.style}
                                                onChange={(e) => setEditingPrize({ ...editingPrize, style: e.target.value })}
                                            />
                                            <label htmlFor="edit-weight">Weight</label>
                                            <input
                                                id="edit-weight"
                                                type="number"
                                                placeholder="Weight"
                                                value={editingPrize.weight}
                                                onChange={(e) => setEditingPrize({ ...editingPrize, weight: parseInt(e.target.value) })}
                                            />
                                            <label htmlFor="edit-vnum">Vnum</label>
                                            <input
                                                id="edit-vnum"
                                                type="number"
                                                placeholder="Vnum"
                                                value={editingPrize.vnum}
                                                onChange={(e) => setEditingPrize({ ...editingPrize, vnum: parseInt(e.target.value) })}
                                            />
                                            <label htmlFor="edit-count">Count</label>
                                            <input
                                                id="edit-count"
                                                type="number"
                                                placeholder="Count"
                                                value={editingPrize.count}
                                                onChange={(e) => setEditingPrize({ ...editingPrize, count: parseInt(e.target.value) })}
                                            />
                                            <button onClick={() => handleUpdatePrize(prize.id)}>Save</button>
                                            <button onClick={handleCancelEdit}>Cancel</button>
                                        </div>
                                    ) : (
                                        <div className="prize-actions">
                                            <FontAwesomeIcon
                                                icon={faEdit}
                                                onClick={() => handleEditClick(prize)}
                                            />
                                            <FontAwesomeIcon
                                                icon={faTrash}
                                                onClick={() => handleDeletePrize(prize.id)}
                                            />
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                        <div className="add-prize">
                            <div className="input-group">
                                <label htmlFor="new-text">Prize Text</label>
                                <input
                                    id="new-text"
                                    placeholder="Prize text"
                                    value={newPrize.text}
                                    onChange={(e) => setNewPrize({ ...newPrize, text: e.target.value })}
                                />
                            </div>
                            <div className="input-group">
                                <label htmlFor="new-image">Image URL</label>
                                <input
                                    id="new-image"
                                    placeholder="Image URL"
                                    value={newPrize.image_uri}
                                    onChange={(e) => setNewPrize({ ...newPrize, image_uri: e.target.value })}
                                />
                            </div>
                            <div className="input-group">
                                <label htmlFor="new-style">Style</label>
                                <input
                                    id="new-style"
                                    placeholder="Style"
                                    value={newPrize.style}
                                    onChange={(e) => setNewPrize({ ...newPrize, style: e.target.value })}
                                />
                            </div>
                            <div className="input-group">
                                <label htmlFor="new-weight">Weight</label>
                                <input
                                    id="new-weight"
                                    type="number"
                                    placeholder="Weight"
                                    value={newPrize.weight}
                                    onChange={(e) => setNewPrize({ ...newPrize, weight: parseInt(e.target.value) })}
                                />
                            </div>
                            <div className="input-group">
                                <label htmlFor="new-vnum">Vnum</label>
                                <input
                                    id="new-vnum"
                                    type="number"
                                    placeholder="Vnum"
                                    value={newPrize.vnum}
                                    onChange={(e) => setNewPrize({ ...newPrize, vnum: parseInt(e.target.value) })}
                                />
                            </div>
                            <div className="input-group">
                                <label htmlFor="new-count">Count</label>
                                <input
                                    id="new-count"
                                    type="number"
                                    placeholder="Count"
                                    value={newPrize.count}
                                    onChange={(e) => setNewPrize({ ...newPrize, count: parseInt(e.target.value) })}
                                />
                            </div>
                            <button onClick={handleAddPrize}>
                                <FontAwesomeIcon icon={faPlus} /> Add Prize
                            </button>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
}