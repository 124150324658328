// user_info.js

import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

let userInfo = {
    country: null,
    ip: null,
    unque_id: null
};

async function initializeUserInfo() {
    // Get IP and approximate country
    try {
        const response = await axios.get('https://ipapi.co/json/');
        userInfo.ip = response.data.ip;
        userInfo.country = response.data.country_name;
    } catch (error) {
        console.error('Failed to fetch IP and country:', error);
    }

    // Generate a pseudo hardware ID
    if (!localStorage.getItem('unque_id')) {
        localStorage.setItem('unque_id', uuidv4());
    }
    userInfo.unque_id = localStorage.getItem('unque_id');
}

// Initialize user info
initializeUserInfo();

let initPromise = null;

export function getUserInfo() {
    if (!initPromise) {
        initPromise = initializeUserInfo();
    }
    return initPromise.then(() => userInfo);
}

// Function to refresh user info if needed
export async function refreshUserInfo() {
    await initializeUserInfo();
    return userInfo;
}