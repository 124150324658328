import axios from 'axios';
import { encryptData, decryptData } from "./app_components/encryptionUtil";
import { getUserInfo, refreshUserInfo } from './user_info';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_HOST || 'http://127.0.0.1:8000/',
    withCredentials: true,
});

async function fetchAndSetCSRFToken() {
    // console.log("process.env.REACT_APP_API_HOST:", process.env.REACT_APP_API_HOST);
    try {
        const response = await axiosInstance.get('/accounts/accommodate-user/');
        const csrfToken = response.data.csrf_token;
        axiosInstance.defaults.headers.common['X-CSRFToken'] = csrfToken;
        // console.log('CSRF token fetched and set:', csrfToken);
    } catch (error) {
        console.error('Failed to fetch CSRF token:', error);
    }
}

axiosInstance.interceptors.request.use((config) => {
    const encryptedToken = localStorage.getItem('access_token');
    if (encryptedToken) {
        const token = decryptData(encryptedToken);
        // console.log('Token fetched from local storage:', token);
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('access_token');
            window.dispatchEvent(new CustomEvent('tokenExpired'));
        }
        return Promise.reject(error);
    }
);

fetchAndSetCSRFToken().then(() => {
    console.log('Info');
});

export default axiosInstance;