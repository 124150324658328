// src/utils/videoCache.js

const DB_NAME = 'VideoCacheDB';
const STORE_NAME = 'videoStore';
const VIDEO_KEY = 'cachedVideo';

const openDB = () => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(DB_NAME, 1);
        request.onerror = () => reject(request.error);
        request.onsuccess = () => resolve(request.result);
        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            db.createObjectStore(STORE_NAME);
        };
    });
};

export const cacheVideo = async (url) => {
    try {
        const response = await fetch(url);
        const blob = await response.blob();
        const db = await openDB();
        const transaction = db.transaction(STORE_NAME, 'readwrite');
        const store = transaction.objectStore(STORE_NAME);
        await new Promise((resolve, reject) => {
            const request = store.put({ blob, timestamp: Date.now() }, VIDEO_KEY);
            request.onerror = () => reject(request.error);
            request.onsuccess = () => resolve();
        });
        return URL.createObjectURL(blob);
    } catch (error) {
        console.error('Error caching video:', error);
        return url;
    }
};

export const getCachedVideo = async () => {
    try {
        const db = await openDB();
        const transaction = db.transaction(STORE_NAME, 'readonly');
        const store = transaction.objectStore(STORE_NAME);
        const cachedData = await new Promise((resolve, reject) => {
            const request = store.get(VIDEO_KEY);
            request.onerror = () => reject(request.error);
            request.onsuccess = () => resolve(request.result);
        });
        if (cachedData && cachedData.blob) {
            return {
                url: URL.createObjectURL(cachedData.blob),
                timestamp: cachedData.timestamp
            };
        }
    } catch (error) {
        console.error('Error retrieving cached video:', error);
    }
    return null;
};

export const clearCachedVideo = async () => {
    try {
        const db = await openDB();
        const transaction = db.transaction(STORE_NAME, 'readwrite');
        const store = transaction.objectStore(STORE_NAME);
        await new Promise((resolve, reject) => {
            const request = store.delete(VIDEO_KEY);
            request.onerror = () => reject(request.error);
            request.onsuccess = () => resolve();
        });
    } catch (error) {
        console.error('Error clearing cached video:', error);
    }
};