import React, { useState } from "react";
import LogoSVG from "./logo";
import { NavIcons } from './NavIcons';
import { toggleTheme } from "./theme";
import "./navbar_style.css";
import { FaDiscord } from 'react-icons/fa';

const Navbar = ({
  onSignInSignUpClick,
  isUserLoggedIn,
  setSelectedOption,
  isWheelSelected,
  setIsWheelSelected,
  activated_status,
  disable_unactivated,
  isLoggedIn,
  disable_not_logged_in,
  notifications
}) => {
  const [isNavbarExpanded, setIsNavbarExpanded] = useState(false);
  const handleNavbarHover = () => setIsNavbarExpanded(true);
  const handleNavbarLeave = () => setIsNavbarExpanded(false);

  const isDisabled = (id) => {
    if (!isLoggedIn && disable_not_logged_in.includes(id)) {
      return true;
    }
    return !activated_status && disable_unactivated.includes(id);
  };

  const handleItemClick = (id) => {
    if (!isDisabled(id)) {
      setSelectedOption(id);
      if (id === "wheel") {
        setIsWheelSelected(!isWheelSelected);
      } else {
        setIsWheelSelected(false);
      }
    }
  };

  const getNotification = (id) => {
    return notifications.find(n => n.id === id);
  };

  const renderNavItem = (id, text, icon) => {
    const disabled = isDisabled(id);
    const tooltipText = !isLoggedIn && disable_not_logged_in.includes(id)
      ? "You need to login to view this element"
      : "Disabled because account needs to validate email";

    const notification = getNotification(id);
    const hasNotification = notification && notification.count > 0;

    return (
      <li
        className={`nav-item ${disabled ? 'disabled' : ''} ${id === 'wheel' && isWheelSelected ? 'selected' : ''}`}
        id={id}
        onClick={() => handleItemClick(id)}
      >
        <div className="nav-item-container">
          <a href="#" className={`nav-link ${disabled ? 'disabled' : ''} ${hasNotification ? 'notification-highlight' : ''}`}>
            <div className="nav-icon-container">
              {icon}
              {!disabled && hasNotification && (
                <div className="notification-popup-container">
                  <div className="notification-popup">{notification.count}</div>
                </div>
              )}
            </div>
            <span className="link-text">{text}</span>
            {disabled && <span className="tooltip">{tooltipText}</span>}
            {hasNotification && (
              <div className="notification-hover-message">
                {notification.notification_message || `You have ${notification.count} notification${notification.count > 1 ? 's' : ''} to take care of`}
              </div>
            )}
          </a>
        </div>
      </li>
    );
  };

  return (
    <nav
      className={isNavbarExpanded ? "navbar expanded" : "navbar"}
      onMouseEnter={handleNavbarHover}
      onMouseLeave={handleNavbarLeave}
    >
      <ul className="navbar-nav">
        <li className="logo">
          <a href="#" className="nav-link">
            <span className="link-text logo-text">Awakening-2</span>
            <LogoSVG />
          </a>
        </li>
        {renderNavItem("home", "Home", <NavIcons.HomeIcon />)}
        {renderNavItem("download", "Download", <NavIcons.DownloadIcon />)}
        {renderNavItem("shop", "Item Shop", <NavIcons.ShoppingIcon />)}
        {renderNavItem("contact", "Contact", <NavIcons.ContactIcon />)}
        {renderNavItem("wheel", "Fortune Wheel", <NavIcons.WheelIcon />)}
        <li
          className="nav-item"
          id="profile"
          onClick={isUserLoggedIn ? () => setSelectedOption("profile") : null}
        >
          <div className="nav-item-container">
            <a href="#"
              className={`nav-link ${getNotification('profile')?.count > 0 ? 'notification-highlight' : ''}`}
              onClick={onSignInSignUpClick}>
              <div className="nav-icon-container">
                <NavIcons.UserIcon />
                {isUserLoggedIn && getNotification('profile')?.count > 0 && (
                  <div className="notification-popup-container">
                    <div className="notification-popup">{getNotification('profile').count}</div>
                  </div>
                )}
              </div>
              <span className="link-text">My Account</span>
              {isUserLoggedIn && getNotification('profile')?.count > 0 && (
                <div className="notification-hover-message">
                  {getNotification('profile').notification_message || `You have ${getNotification('profile').count} notification${getNotification('profile').count > 1 ? 's' : ''} to take care of`}
                </div>
              )}
            </a>
          </div>
        </li>
        <li
          className="nav-item"
          id="discord"
        >
          <div className="nav-item-container">
            <a
              href="https://discord.com/invite/8GSTzkbwnr"
              target="_blank"
              rel="noopener noreferrer"
              className="nav-link"
            >
              <div className="nav-icon-container">
                <FaDiscord className="nav-icon-discord" />
              </div>
              <span className="link-text">Discord</span>
            </a>
          </div>
        </li>
        <li className="nav-item" id="themeButton" onClick={() => toggleTheme()}>
          <a href="#" className="nav-link">
            <NavIcons.MoonStarsIcon />
            <NavIcons.SunIcon />
            <NavIcons.SunglassesIcon />
            <span className="link-text">Themify</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;