import React, { useState } from 'react';

const EditableContent = ({ content, onEdit, onDelete }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedContent, setEditedContent] = useState(content);

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSave = () => {
        onEdit(editedContent);
        setIsEditing(false);
    };

    const handleCancel = () => {
        setEditedContent(content);
        setIsEditing(false);
    };

    return (
        <div className="editable-content">
            {isEditing ? (
                <>
                    <textarea
                        value={editedContent}
                        onChange={(e) => setEditedContent(e.target.value)}
                    />
                    <div className="edit-buttons">
                        <button onClick={handleSave}>Save</button>
                        <button onClick={handleCancel}>Cancel</button>
                    </div>
                </>
            ) : (
                <div className="home-card-content">{content}</div>
            )}
            <div className="content-buttons">
                {!isEditing && <button onClick={handleEdit}>Edit</button>}
                <button onClick={onDelete} className="delete-content-btn">Delete</button>
            </div>
        </div>
    );
};

export default EditableContent;