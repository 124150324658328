import React, { useState } from "react";
import LoggedInUserComponent from "./app_components/loggedInUserComponents/LoggedInUserComponent";
import { CookieConsentProvider } from "./cookies/cookie_consent";
import CookiePolicyModal from "./cookies/CookiePolicyModal";
import "./appStyle.css";


function App() {
  const [isCookiePolicyVisible, setIsCookiePolicyVisible] = useState(false);

  const toggleCookiePolicyModal = () => {
    setIsCookiePolicyVisible(!isCookiePolicyVisible);
  };

  return (
    <div className="app-container">
      <div id="leaves">
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
      </div>
      <CookieConsentProvider onCookiePolicyClick={toggleCookiePolicyModal}>
        <LoggedInUserComponent />
        <CookiePolicyModal
          isVisible={isCookiePolicyVisible}
          onClose={() => setIsCookiePolicyVisible(false)}
        />
      </CookieConsentProvider>
    </div>
  );
}

export default App;
