import React from 'react';

const PlayerRankings = ({
    players,
    currentPage,
    totalPages,
    isLoading,
    error,
    onPageChange
}) => {
    if (isLoading) {
        return <div className="loading-spinner"></div>;
    }

    if (error) {
        return <div className="error-message">{error}</div>;
    }

    return (
        <div className="player-rankings-container">
            <ul className="player-list">
                {players.map((player, index) => (
                    <li key={player.id} className="player-item">
                        <span className="player-rank">{(currentPage - 1) * 10 + index + 1}</span>
                        <span className="player-name">{player.name}</span>
                        <span className="player-level">Lv. {player.level}</span>
                    </li>
                ))}
            </ul>
            <div className="pagination">
                <button
                    onClick={() => onPageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    Previous
                </button>
                <span>{currentPage} / {totalPages}</span>
                <button
                    onClick={() => onPageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default PlayerRankings;