// UserContext.js
import React, { createContext, useState, useContext, useEffect } from "react";
import axiosInstance from './axiosConfig';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    const fetchUserInfo = async () => {
        try {
            const response = await axiosInstance.get('/user-info/');
            setUser(response.data);
        } catch (error) {
            console.error("Error fetching user info:", error);
            setUser(null);
        }
    };

    useEffect(() => {
        if (user === null) {
            fetchUserInfo();
        }
    }, [user]);

    return (
        <UserContext.Provider value={{ user, setUser, fetchUserInfo }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);