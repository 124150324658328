// LoadingAnimation.jsx
import React, { useState, useEffect } from 'react';
import "./loadingAnim.css";

const LoadingAnimation = () => {
    const [loadingText, setLoadingText] = useState('Initializing Security Check');
    const [dots, setDots] = useState('');

    useEffect(() => {
        const messages = [
            'Initializing Security Check',
            'Verifying connection integrity',
            'Loading Game Data',
            'Preparing Awakening2',
            'Almost Ready'
        ];
        let currentIndex = 0;
        let dotCount = 0;

        const textInterval = setInterval(() => {
            currentIndex = (currentIndex + 1) % messages.length;
            setLoadingText(messages[currentIndex]);
        }, 3000);

        const dotsInterval = setInterval(() => {
            dotCount = (dotCount + 1) % 4;
            setDots('.'.repeat(dotCount));
        }, 500);

        return () => {
            clearInterval(textInterval);
            clearInterval(dotsInterval);
        };
    }, []);

    return (
        <div className="loading-animation-container">
            <div className="security-circle"></div>
            <div className="loading-content">
                {/* <div className="loading-spinner-large"></div> */}
                <p className="loading-text">{loadingText}<span className="loading-dots">{dots}</span></p>
                <div className="progress-bar">
                    <div className="progress-fill"></div>
                </div>
            </div>
        </div>
    );
};

export default LoadingAnimation;