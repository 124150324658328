import React, { useState, useEffect, useRef, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './homeStyle.css';
import PlayerRankings from './PlayerRankings';
import axiosInstance from '../../../axiosConfig';
import EditableTitle from './EditableTitle';
import EditableContent from './EditableContent';
import { useUser } from '../../../UserContext';
import { cacheVideo, getCachedVideo, getCachedVideoTimestamp, clearCachedVideo } from './videoCache';
import LoadingAnimation from '../../../loadingAnimation';

const CollapsibleSection = ({ title, content, isOpen, onClick, onTitleEdit, onContentEdit, onDelete, onAddContent, onDeleteContent }) => {
    const { user } = useUser();
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    const isUserAdmin = () => user && user.isAdmin === true;

    return (
        <div className={`collapsible-section ${isOpen ? 'expanded' : ''}`}>
            <div onClick={onClick} className="collapsible-title-container">
                {isUserAdmin() ? (
                    <EditableTitle title={title} onEdit={onTitleEdit} />
                ) : (
                    <h1 className="collapsible-title">{title}</h1>
                )}
                {isUserAdmin() && (
                    <button className="delete-section-btn" onClick={(e) => {
                        e.stopPropagation();
                        setShowDeleteConfirm(true);
                    }}>Delete Section</button>
                )}
            </div>
            {showDeleteConfirm && isUserAdmin() && (
                <div className="delete-confirm">
                    <p>Are you sure you want to delete this section?</p>
                    <button onClick={() => onDelete()}>Yes, delete</button>
                    <button onClick={() => setShowDeleteConfirm(false)}>No, keep it</button>
                </div>
            )}
            <AnimatePresence initial={false}>
                {isOpen && (
                    <motion.div
                        className="collapsible-content"
                        initial="collapsed"
                        animate="expanded"
                        exit="collapsed"
                        variants={{
                            expanded: { opacity: 1, height: 'auto' },
                            collapsed: { opacity: 0, height: 0 }
                        }}
                        transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                        <ul className='home-cards-parent'>
                            {content.map((item, index) => (
                                <motion.li
                                    className='home-cards-li'
                                    key={index}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{
                                        duration: 0.5,
                                        delay: index * 0.05
                                    }}
                                >
                                    {isUserAdmin() ? (
                                        <EditableContent
                                            content={item.content || (
                                                <>
                                                    {item.before_link_text}
                                                    <a href={item.link} target="_blank" rel="noopener noreferrer">
                                                        {item.hypertext}
                                                    </a>
                                                    {item.after_link_text}
                                                </>
                                            )}
                                            onEdit={(editedContent) => onContentEdit(index, editedContent)}
                                            onDelete={() => onDeleteContent(title, index)}
                                        />
                                    ) : (
                                        <div className="home-card-content">
                                            {item.content || (
                                                <>
                                                    {item.before_link_text}
                                                    <a href={item.link} target="_blank" rel="noopener noreferrer">
                                                        {item.hypertext}
                                                    </a>
                                                    {item.after_link_text}
                                                </>
                                            )}
                                        </div>
                                    )}
                                </motion.li>
                            ))}
                        </ul>
                        {isUserAdmin() && (
                            <button className="add-content-btn" onClick={() => onAddContent(title)}>Add New Content</button>
                        )}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

const LoadingSpinner = () => (
    <div className="loading-spinner-container">
        <div className="loading-spinner"></div>
    </div>
);

const HomePage = ({
    playerStats,
    playerStatsError,
    isLoading: playerStatsLoading,
    contentMap,
    contentError,
    announcementsLoading,
    players,
    currentPage,
    totalPages,
    rankingsLoading,
    rankingsError,
    onPageChange,
    videoUrl,
}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [visibleSections, setVisibleSections] = useState([]);
    const [openSection, setOpenSection] = useState(null);
    const [editableContentMap, setEditableContentMap] = useState({});
    const [newSectionTitle, setNewSectionTitle] = useState('');
    const [isAddingSectionTitle, setIsAddingSectionTitle] = useState(false);
    const { user } = useUser();
    const videoRef = useRef(null);
    const [cachedVideoUrl, setCachedVideoUrl] = useState(null);

    const isUserAdmin = () => {
        return user && user.isAdmin === true;
    };

    useEffect(() => {
        let mounted = true;

        const initializeContent = async () => {
            try {
                // Load video
                const cachedVideo = await getCachedVideo();
                if (!mounted) return;

                if (cachedVideo) {
                    const currentTime = Date.now();
                    const oneDay = 24 * 60 * 60 * 1000;

                    if (currentTime - cachedVideo.timestamp < oneDay) {
                        setCachedVideoUrl(cachedVideo.url);
                    } else {
                        await clearCachedVideo();
                        if (videoUrl) {
                            const newCachedUrl = await cacheVideo(videoUrl);
                            setCachedVideoUrl(newCachedUrl);
                        }
                    }
                } else if (videoUrl) {
                    const newCachedUrl = await cacheVideo(videoUrl);
                    setCachedVideoUrl(newCachedUrl);
                }

                // Wait a minimum time to prevent flash
                await new Promise(resolve => setTimeout(resolve, 1000));

                if (!mounted) return;
                setIsLoading(false);
            } catch (error) {
                console.error('Error initializing content:', error);
                if (mounted) setIsLoading(false);
            }
        };

        initializeContent();

        return () => {
            mounted = false;
        };
    }, [videoUrl]);

    const memoizedTitleSection = useMemo(() => (
        <div className="title-main-cont">
            <div className="video-wrapper">
                {cachedVideoUrl && (
                    <video
                        ref={videoRef}
                        className="background-video"
                        autoPlay
                        loop
                        muted
                        playsInline
                        onLoadedData={() => setIsLoading(false)}
                        onError={(e) => {
                            console.error('Video loading error:', e);
                            setIsLoading(false);
                        }}
                    >
                        <source src={cachedVideoUrl} type="video/webm" />
                        Your browser does not support the video tag.
                    </video>
                )}
                <div className="video-overlay"></div>
            </div>
            <div className="home-page-title-container">
                <h1 className="awakening-text">Awakening2</h1>
            </div>
        </div>
    ), [cachedVideoUrl]);

    useEffect(() => {
        if (contentMap && Object.keys(contentMap).length > 0) {
            setEditableContentMap(contentMap);
            const titles = Object.keys(contentMap);
            titles.forEach((title, index) => {
                setTimeout(() => {
                    setVisibleSections(prev => [...prev, title]);
                }, index * 200);
            });
        }
    }, [contentMap]);


    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.addEventListener('error', (e) => {
                console.error('Video error:', e);
            });
        }
    }, []);

    const handleToggle = (title) => {
        setOpenSection(openSection === title ? null : title);
    };

    const handleTitleEdit = async (oldTitle, newTitle) => {
        try {
            await axiosInstance.put('/info/announcements', { old_title: oldTitle, new_title: newTitle });
            setEditableContentMap(prevMap => {
                const newMap = { ...prevMap };
                newMap[newTitle] = newMap[oldTitle];
                delete newMap[oldTitle];
                return newMap;
            });
        } catch (error) {
            console.error('Error updating title:', error);
            alert('Failed to update title. Please try again.');
        }
    };

    const handleContentEdit = async (title, index, editedContent) => {
        try {
            await axiosInstance.patch('/info/announcements', {
                id: editableContentMap[title][index].id,
                content: editedContent
            });
            setEditableContentMap(prevMap => {
                const newMap = { ...prevMap };
                newMap[title][index] = { ...newMap[title][index], content: editedContent };
                return newMap;
            });
        } catch (error) {
            console.error('Error updating content:', error);
            alert('Failed to update content. Please try again.');
        }
    };

    const handleAddNewSection = async () => {
        if (newSectionTitle.trim() === '') {
            alert('Please enter a title for the new section');
            return;
        }

        try {
            const response = await axiosInstance.post('/info/announcements', {
                section: newSectionTitle,
                content: 'Content for section will be available shortly',
                order: 1
            });

            if (response.status === 201) {
                const newSection = response.data;
                setEditableContentMap(prevMap => ({
                    ...prevMap,
                    [newSectionTitle]: [{
                        id: newSection.id,
                        content: newSection.content,
                        order: newSection.order
                    }]
                }));
                setVisibleSections(prev => [...prev, newSectionTitle]);
                setNewSectionTitle('');
                setIsAddingSectionTitle(false);
            }
        } catch (error) {
            console.error('Error adding new section:', error);
            alert('Failed to add new section. Please try again.');
        }
    };

    const handleDeleteSection = async (title) => {
        try {
            await axiosInstance.delete('/info/announcements', { params: { section: title } });
            setEditableContentMap(prevMap => {
                const newMap = { ...prevMap };
                delete newMap[title];
                return newMap;
            });
            setVisibleSections(prev => prev.filter(section => section !== title));
        } catch (error) {
            console.error('Error deleting section:', error);
            alert('Failed to delete section. Please try again.');
        }
    };

    const handleAddContent = async (sectionTitle) => {
        try {
            const response = await axiosInstance.post('/info/announcements', {
                section: sectionTitle,
                content: 'New content',
                order: editableContentMap[sectionTitle].length + 1
            });

            if (response.status === 201) {
                const newContent = response.data;
                setEditableContentMap(prevMap => ({
                    ...prevMap,
                    [sectionTitle]: [
                        ...prevMap[sectionTitle],
                        {
                            id: newContent.id,
                            content: newContent.content,
                            order: newContent.order
                        }
                    ]
                }));
            }
        } catch (error) {
            console.error('Error adding new content:', error);
            alert('Failed to add new content. Please try again.');
        }
    };

    const handleDeleteContent = async (sectionTitle, contentIndex) => {
        try {
            const contentToDelete = editableContentMap[sectionTitle][contentIndex];
            await axiosInstance.delete(`/info/announcements/content/${contentToDelete.id}`);

            setEditableContentMap(prevMap => ({
                ...prevMap,
                [sectionTitle]: prevMap[sectionTitle].filter((_, index) => index !== contentIndex)
            }));
        } catch (error) {
            console.error('Error deleting content:', error);
            alert('Failed to delete content. Please try again.');
        }
    };

    const renderServerInfo = () => {
        if (playerStatsLoading) {
            return <LoadingSpinner />;
        }

        if (playerStatsError) {
            return <p className="error-message">{playerStatsError}</p>;
        }

        return (
            <div className="server-info">
                <div className="sv-info-line">
                    <h4 className="info-name">Online Players</h4>
                    <p className="info-value">{playerStats.online_players}</p>
                </div>
                <div className="sv-info-line">
                    <h4 className="info-name">Total Characters</h4>
                    <p className="info-value">{playerStats.total_characters}</p>
                </div>
                <div className="sv-info-line">
                    <h4 className="info-name">Total Accounts</h4>
                    <p className="info-value">{playerStats.total_accounts}</p>
                </div>
            </div>
        );
    };

    return (
        isLoading ? <LoadingAnimation /> : (
            <>
                {memoizedTitleSection}
                <div className="home-container">
                    <div className="announcement-container">
                        {isUserAdmin() && (
                            !isAddingSectionTitle ? (
                                <button className="add-section-btn" onClick={() => setIsAddingSectionTitle(true)}>
                                    Add New Section
                                </button>
                            ) : (
                                <div className="add-section-form">
                                    <input
                                        type="text"
                                        value={newSectionTitle}
                                        onChange={(e) => setNewSectionTitle(e.target.value)}
                                        placeholder="Enter section title"
                                    />
                                    <button onClick={handleAddNewSection}>Save</button>
                                    <button onClick={() => setIsAddingSectionTitle(false)}>Cancel</button>
                                </div>
                            )
                        )}
                        {announcementsLoading ? (
                            <LoadingSpinner />
                        ) : contentError ? (
                            <p className="error-message">{contentError}</p>
                        ) : (
                            <AnimatePresence>
                                {Object.entries(editableContentMap).map(([title, content], index) => (
                                    <motion.div
                                        key={title}
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={visibleSections.includes(title) ? { opacity: 1, y: 0 } : {}}
                                        exit={{ opacity: 0, y: -20 }}
                                        transition={{ duration: 0.5, delay: index * 0.1 }}
                                    >
                                        <CollapsibleSection
                                            title={title}
                                            content={content}
                                            isOpen={openSection === title}
                                            onClick={() => handleToggle(title)}
                                            onTitleEdit={(newTitle) => handleTitleEdit(title, newTitle)}
                                            onContentEdit={(index, editedContent) => handleContentEdit(title, index, editedContent)}
                                            onDelete={() => handleDeleteSection(title)}
                                            onAddContent={handleAddContent}
                                            onDeleteContent={handleDeleteContent}
                                        />
                                    </motion.div>
                                ))}
                            </AnimatePresence>
                        )}
                        <div className="empty-card"></div>
                    </div>
                    <div className="server-info-container">
                        <h3 className="server-info-title">Server Info</h3>
                        {renderServerInfo()}
                    </div>

                    <div className="rankings">
                        <div className="player-rankings">
                            <div className="player-info-container">
                                <h3 className="player-info-title">Player Rankings</h3>
                                <PlayerRankings
                                    players={players}
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    isLoading={rankingsLoading}
                                    error={rankingsError}
                                    onPageChange={onPageChange}
                                />
                            </div>
                        </div>
                        <div className="guild-rankings"></div>
                    </div>
                </div>
            </>
        )
    );
};

export default HomePage;