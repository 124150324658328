// src/services/authService.js

import axiosInstance from "../../axiosConfig";

export const loginUser = async (email, password) => {
    try {
        const response = await axiosInstance.post(
            "/accounts/login/",
            { email, password },
            { withCredentials: true }
        );
        return response;
    } catch (error) {
        throw error;
    }
};

export const createUser = async (username, password, email, socialId, securityCode) => {
    const data = {
        login: username,
        password,
        social_id: socialId,
        email,
        securitycode: securityCode,
        status: "OK",
        availdt: new Date(),
        create_time: new Date(),
        last_play: new Date(),
        gold_expire: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        silver_expire: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        safebox_expire: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        autoloot_expire: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        fish_mind_expire: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        marriage_fast_expire: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        money_drop_rate_expire: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    };

    try {
        const response = await axiosInstance.post(
            "/accounts/create/",
            data,
            { withCredentials: true }
        );
        console.log("Response:", response);
        return response;
    } catch (error) {
        throw error;
    }
};


export const sendPasswordResetCode = async (email) => {
    try {
        const response = await axiosInstance.post(
            "/accounts/send-reset-code/",
            { email },
            { withCredentials: true }
        );
        return response;
    } catch (error) {
        throw error;
    }
};

export const resetPassword = async (email, resetCode, newPassword) => {
    try {
        const response = await axiosInstance.post(
            "/accounts/reset-password/",
            { email, reset_code: resetCode, new_password: newPassword },
            { withCredentials: true }
        );
        return response;
    } catch (error) {
        throw error;
    }
};