import React, { useState, useEffect, useCallback } from "react";
import "./navbar/style.css";
import Navbar from "./navbar/Navbar";
import "react-multi-carousel/lib/styles.css";
import SignInSignUpForm from "./../sign_in_up/SignInSignUpForm";
import DownloadClient from "./download_client/DownloadClient";
import HomePage from './homeComp/homePage';
import WheelComponent from './wheel/Wheel.js';
import axiosInstance from '../../axiosConfig';
import ProfileComponent from './profile/ProfileComponent';
import { useNotifications } from './notificationsLogic';
import { fetchVideo } from './homeComp/videoUtils';
import { encryptData, decryptData } from "../encryptionUtil.js";
import { jwtDecode } from "jwt-decode";

function LoggedInUserComponent({ onCookiePolicyClick }) {
  const [showSignInSignUp, setShowSignInSignUp] = useState(false);
  const [isNavbarExpanded, setIsNavbarExpanded] = useState(false);
  const [selectedOption, setSelectedOption] = useState("home");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [logoutSuccess, setLogoutSuccess] = useState(false);
  const [isWheelSelected, setIsWheelSelected] = useState(false);
  const [availablePrizes, setAvailablePrizes] = useState([]);
  const [hasPlayedEnough, setHasPlayedEnough] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [activationStatus, setActivationStatus] = useState(null);
  const [playerStats, setPlayerStats] = useState({
    online_players: 0,
    total_characters: 0,
    total_accounts: 0
  });
  const [playerStatsError, setPlayerStatsError] = useState(null);
  const [contentMap, setContentMap] = useState({});
  const [contentError, setContentError] = useState(null);
  const [announcementsLoading, setAnnouncementsLoading] = useState(true);
  const [videoUrl, setVideoUrl] = useState(null);
  const [players, setPlayers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [rankingsLoading, setRankingsLoading] = useState(true);
  const [rankingsError, setRankingsError] = useState(null);

  const [availableSpins, setAvailableSpins] = useState(0);

  const { notifications, updateNotifications } = useNotifications(isLoggedIn, activationStatus, availableSpins);
  const [suddenClose, setSuddenClose] = useState(false);

  useEffect(() => {
    const loadVideo = async () => {
      const url = await fetchVideo();
      if (url) {
        setVideoUrl(url);
      }
    };

    loadVideo();

    return () => {
      if (videoUrl) {
        URL.revokeObjectURL(videoUrl);
      }
    };
  }, []);

  const fetchPlayerStats = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get('/player-stats/');
      setPlayerStats(response.data);
      setPlayerStatsError(null);
    } catch (error) {
      console.error('Error fetching player stats:', error);
      setPlayerStatsError('Could not load server info');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchAnnouncements = useCallback(async () => {
    setAnnouncementsLoading(true);
    try {
      const response = await axiosInstance.get('/info/announcements');
      setContentMap(response.data);
      setContentError(null);
    } catch (error) {
      console.error('Error fetching content map:', error);
      setContentError('Failed to load announcements. Please try again later.');
    } finally {
      setAnnouncementsLoading(false);
    }
  }, []);

  const fetchPlayerRankings = useCallback(async (page) => {
    setRankingsLoading(true);
    try {
      const response = await axiosInstance.get(`/player-rankings/?page=${page}`);
      const data = response.data;
      setPlayers(data.results);
      setTotalPages(Math.ceil(data.count / 10));
      setRankingsError(null);
    } catch (error) {
      console.error('Error fetching player rankings:', error);
      setRankingsError('Failed to fetch player rankings');
    } finally {
      setRankingsLoading(false);
    }
  }, []);

  const fetchWheelData = useCallback(async () => {
    setIsLoading(true);
    try {
      const prizesResponse = await axiosInstance.get('/wheel-awards/available-prizes/');
      // console.log('Prizes response:', prizesResponse);
      const prizes = prizesResponse.data.map(item => ({
        id: item.id,
        image_uri: { uri: item.image_uri },
        style: { backgroundColor: item.style },
        text: item.text,
        weight: item.weight,
        vnum: item.vnum,
        count: item.count
      }));
      setAvailablePrizes(prizes);

      if (isLoggedIn) {
        const playTimeResponse = await axiosInstance.get('/get-played-time/');
        setHasPlayedEnough(playTimeResponse.data.has_played_30_min);

        // Fetch available spins
        const ip = await fetch('https://api.ipify.org?format=json')
          .then(response => response.json())
          .then(data => data.ip);

        const spinsResponse = await axiosInstance.get(`/wheel-awards/user-spin/?ip=${ip}`);
        setAvailableSpins(spinsResponse.data.number_of_spins);
      }
    } catch (error) {
      console.error('Error fetching wheel data:', error);
      setError('Failed to load wheel data. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, [isLoggedIn]);

  const fetchActivationStatus = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/activated-account/');
      setActivationStatus(response.data.activated_account);
    } catch (err) {
      console.error('Error fetching activation status:', err);
      setError('Failed to fetch activation status. Please try again.');
    }
  }, []);

  useEffect(() => {
    fetchWheelData();
  }, [isLoggedIn]);

  useEffect(() => {
    if (selectedOption !== "wheel") {
      fetchPlayerStats();
      fetchAnnouncements();
      fetchPlayerRankings(1);
      const interval = setInterval(fetchPlayerStats, 30000);
      return () => clearInterval(interval);
    }
  }, [selectedOption, fetchPlayerStats, fetchAnnouncements, fetchPlayerRankings, isLoggedIn]);

  useEffect(() => {
    if (logoutSuccess) {
      const timer = setTimeout(() => {
        setSelectedOption("home");
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [logoutSuccess]);

  useEffect(() => {
    setIsWheelSelected(selectedOption === "wheel");
  }, [selectedOption]);

  useEffect(() => {
    const handleTokenExpired = () => {
      setIsLoggedIn(false);
      setTokenExpired(true);
      setShowSignInSignUp(true);
    };

    window.addEventListener('tokenExpired', handleTokenExpired);

    return () => {
      window.removeEventListener('tokenExpired', handleTokenExpired);
    };
  }, []);


  useEffect(() => {
    const checkTokenExpiration = () => {
      // console.log("Starting token expiration check");
      const encryptedToken = localStorage.getItem('access_token');

      if (!encryptedToken) {
        // console.log("No token found in localStorage");
        handleLogout();
      } else {
        // console.log("Encrypted token found");
        try {
          // console.log("Attempting to decrypt token");
          const decryptedToken = decryptData(encryptedToken);
          // console.log("Decrypted token:", decryptedToken);

          // console.log("Attempting to decode JWT");
          const decodedToken = jwtDecode(decryptedToken);
          // console.log("Decoded token:", decodedToken);

          if (decodedToken && decodedToken.exp) {
            // console.log("Token has expiration data");
            const currentTime = Math.floor(Date.now() / 1000);
            console.log("Current time:", currentTime, "Token expiration:", decodedToken.exp);

            if (currentTime < decodedToken.exp) {
              console.log("Token is still valid");
              setIsLoggedIn(true);
              setTokenExpired(false);
            } else {
              console.log("Token has expired");
              handleLogout();
            }
          } else {
            console.log("Token is missing expiration data");
            handleLogout();
          }
        } catch (error) {
          // console.error('Error processing token:', error);
          handleLogout();
        }
      }
    };

    checkTokenExpiration();
    const interval = setInterval(checkTokenExpiration, 9600000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      fetchActivationStatus();
    }
  }, [isLoggedIn, fetchActivationStatus]);

  const handleLogout = () => {
    localStorage.clear();
    setIsLoggedIn(false);
    setLogoutSuccess(true);
    setActivationStatus(false);
  };

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
    setLogoutSuccess(false);
    setTokenExpired(false);
    fetchActivationStatus();
    fetchWheelData();
  };

  const handleEmailActivation = () => {
    setActivationStatus(true);
  };

  const toggleSignInSignUp = () => {
    if (!isLoggedIn || suddenClose) {
      setShowSignInSignUp(!showSignInSignUp);
      if (suddenClose)
        setSuddenClose(false);
    }
  };

  const handleNavbarHover = () => {
    setIsNavbarExpanded(true);
  };

  const handleNavbarLeave = () => {
    setIsNavbarExpanded(false);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetchPlayerRankings(newPage);
  };

  const handleSuccessfulSpin = useCallback(() => {
    setAvailableSpins(prevSpins => {
      const newSpinCount = Math.max(0, prevSpins - 1);
      updateNotifications('wheel', newSpinCount, newSpinCount > 0 ? `You have ${newSpinCount} spin${newSpinCount > 1 ? 's' : ''} available!` : '');
      return newSpinCount;
    });
  }, [updateNotifications]);

  const renderContent = () => {
    switch (selectedOption) {
      case "home":
        return (
          <div className="home-page-container">
            <HomePage
              playerStats={playerStats}
              playerStatsError={playerStatsError}
              isLoading={isLoading}
              contentMap={contentMap}
              contentError={contentError}
              announcementsLoading={announcementsLoading}
              players={players}
              currentPage={currentPage}
              totalPages={totalPages}
              rankingsLoading={rankingsLoading}
              rankingsError={rankingsError}
              onPageChange={handlePageChange}
              videoUrl={videoUrl}
            />
          </div>
        );
      case "download":
        return <DownloadClient />;
      case "shop":
        return <h1>Item shop in development</h1>;
      case "contact":
        return <><h1>Discord: astro.mg</h1></>;
      case "wheel":
        return (
          <WheelComponent
            availablePrizes={availablePrizes}
            hasPlayedEnough={hasPlayedEnough}
            isLoading={isLoading}
            error={error}
            availableSpins={availableSpins}
            onSuccessfulSpin={handleSuccessfulSpin}
          />
        );
      case "profile":
        return (
          <div className="user-board">
            {logoutSuccess ? (
              <p>You've successfully logged out.</p>
            ) : (
              <ProfileComponent
                emailActivated={activationStatus}
                onLogout={handleLogout}
                isLoggedIn={isLoggedIn}
                onEmailActivation={handleEmailActivation}
              />
            )}
          </div>
        );
      default:
        return <h1>Home</h1>;
    }
  };

  return (
    <div className="user-content-container">
      <Navbar
        setSelectedOption={setSelectedOption}
        handleNavbarHover={handleNavbarHover}
        handleNavbarLeave={handleNavbarLeave}
        isNavbarExpanded={isNavbarExpanded}
        isUserLoggedIn={isLoggedIn}
        onSignInSignUpClick={toggleSignInSignUp}
        isWheelSelected={isWheelSelected}
        setIsWheelSelected={setIsWheelSelected}
        activated_status={activationStatus}
        disable_unactivated={['wheel', 'shop']}
        isLoggedIn={isLoggedIn}
        disable_not_logged_in={['wheel', 'shop']}
        notifications={notifications}
      />
      <main className={`content ${isNavbarExpanded ? "shiftContent" : isNavbarExpanded === false ? "shiftContentBack" : ""}`}>
        {renderContent()}
      </main>
      {showSignInSignUp && (
        <div className="modal-overlay">
          <div className="modal-content">
            <SignInSignUpForm
              toggleForm={toggleSignInSignUp}
              onLoginSuccess={handleLoginSuccess}
              setSelectedOption={setSelectedOption}
              setSuddenClose={setSuddenClose}
            />
          </div>
        </div>
      )}
      {tokenExpired && (
        <div className="modal-overlay">
          <div className="modal-content">
            {/* <p>Your session has expired. Please log in again.</p> */}
            {/* <SignInSignUpForm toggleForm={toggleSignInSignUp} onLoginSuccess={handleLoginSuccess} /> */}
          </div>
        </div>
      )}
    </div>
  );
}

export default LoggedInUserComponent;